<template>
  <!-- 底部 -->
  <div id="footerDiv" class="bottomDiv" :style="'color: '+color+'; padding-bottom: '+paddingBottom+''">
    <div>
      <span class="btn" @click="onClickButtonBtn('agree')">User Agreement</span>
      <span class="line"></span>
      <span class="btn" @click="onClickButtonBtn('policy')">Privacy Policy</span>
    </div>
    <div>Mail：service@***.com</div>
    <div>Copyright © 2020-2024. All rights Reserved.</div>
  </div>
</template>

<script>
export default {
  name: "footerUI",
  props: {
    showFooterAd: {
      type: Boolean,
      default: false
    },
    fontColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      color: "",
      paddingBottom: "24px"
    };
  },
  watch: {},
  mounted() {
    this.color = this.fontColor;
    if (this.showFooterAd) {
      this.paddingBottom = "74px";
    }
  },
  beforeDestroy() {
  },
  created() {

  },
  methods: {
    onClickButtonBtn(type) {
      let url = type === "agree" ? "userAgreement" : "privacyPolicy";
      this.$router.push("/" + url);
    },
  },
}
</script>

<style lang="scss" scoped>
.bottomDiv {
  text-align: center;
  font-size: 12px;
  height: 60px;
  line-height: 1.6;
  padding: 24px 0;

  .btn {

  }

  .line {
    border-right: 1px solid black;
    margin: 0 10px;
  }
}
</style>
