<template>
  <div class="homePageDiv">
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="categoryDrawer = !categoryDrawer">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z"
                  fill="#ffffff"></path>
            </svg>
          </el-button>
        </div>
        <div class="logoDiv">
          <img class="logoImage" src="/images/logo2.png" alt=""/>
        </div>
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="onClickSearch">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M426.666667 170.666667a256 256 0 1 0 0 512 256 256 0 0 0 0-512z m-341.333334 256a341.333333 341.333333 0 1 1 610.986667 209.322666l229.845333 229.845334a42.666667 42.666667 0 0 1-60.330666 60.330666l-229.845334-229.845333A341.333333 341.333333 0 0 1 85.333333 426.666667z"
                  fill="#fff"></path>
            </svg>
          </el-button>
        </div>
      </div>
      <div class="informationDataDiv">
        <div v-if="category.id > 0" class="div">
          <div style="height: 10px;"></div>
          <div v-for="(value, key) in informationData" :key="'informationData_' + key" class="informationSection">
            <div class="informationTitle">
              <span>
                {{ value[0].type }}
              </span>
            </div>
            <div class="fifthItem">
              <div v-for="(information, index) in value" :key="'informationItem_' + index" class="informationItem"
                   @click="onClickItem(information.index)">
                <div class="informationContent">
                  <div class="title">
                    {{ information.title }}
                  </div>
                  <div class="description">
                    {{ information.desc }}
                  </div>
                </div>
                <img :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="div">
          <div v-for="(value, key, idx) in informationData" :key="'informationData_' + key" class="informationSection">
            <div class="informationTitle" v-if="idx > 0">
              <span>
                {{ value[0].type }}
              </span>
            </div>
            <div :class="getClassName(idx)">
              <div v-for="(information, index) in value" :key="'informationItem_' + index" class="informationItem"
                   @click="onClickItem(information.index)">
                <img v-if="idx === 0 || idx === 1 || idx === 2"
                     :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
                <div class="informationSubTitle" v-if="idx === 0 && index > 0">
                  <span>
                    {{ information.title }}
                  </span>
                </div>
                <div class="informationContent" v-if="idx === 1">
                  <div class="title">
                    {{ information.title }}
                  </div>
                  <div class="description">
                    {{ information.desc }}
                  </div>
                </div>
                <div class="informationContent" v-if="idx > 1">
                  <div class="title">
                    {{ information.title }}
                  </div>
                  <div class="description">
                    {{ information.desc }}
                  </div>
                </div>
                <img v-if="idx > 3" :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer :with-header="false" size="100%" style="max-width: 750px; margin: auto;"
               direction="ttb" :visible.sync="categoryDrawer" :show-close="false" :append-to-body="true">
      <div :style="getDrawerTitleStyle">
        <el-button type="text" style="padding: 0; border: 0;" @click="categoryDrawer = !categoryDrawer">
          <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
            <path
                d="M563.8 512l262.5-312.9c4.4-5.2 0.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9c-4.4 5.2-0.7 13.1 6.1 13.1h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                fill="#ffffff"></path>
          </svg>
        </el-button>
        <img class="logoImage" src="/images/menu_logo1.png" alt="" style="display: block; width: 89px;"/>
        <div style="width: 30px;"></div>
      </div>
      <div v-for="(_category, index) in categoryList" :key="'news_category_' + index"
           @click="onSearchByTag(_category)" :style="getDrawerItemStyle(_category)">
        {{ _category.name }}
      </div>
      <div style="height: 20px;"/>
    </el-drawer>
    <footerUI/>
    <BackTopComp/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import BackTopComp from "@/view/BackTopComp";
import InformationData from "@/view/template1/model/InformationData";

export default {
  components: {
    footerUI, BackTopComp
  },
  data() {
    return {
      categoryDrawer: false,
      category: null,
      categoryList: JSON.parse(JSON.stringify(InformationData.categoryList)),
      informationData: {},
      ossUrl: "https://www.gamesoss.com/information",
    };
  },
  computed: {
    getClassName() {
      return (idx) => {
        if (idx === 0) {
          return "firstItem";
        } else if (idx === 1) {
          return "secondItem";
        } else if (idx === 2) {
          return "thirdItem";
        } else if (idx === 3) {
          return "fourthItem";
        } else {
          return "fifthItem";
        }
      }
    },
    getDrawerTitleStyle() {
      return ({
        'height': '48px',
        'background-color': '#ef6c00',
        'width': '100%',
        'display': 'flex',
        'justify-content': 'space-between',
        'padding': '0 10px',
        'align-items': 'center',
        'position': 'sticky',
        'top': '0'
      });
    },
    getDrawerItemStyle() {
      return _category => ({
        'padding': '15px',
        'text-align': 'left',
        'font-size': '16px',
        'font-weight': 'bold',
        color: _category.id === this.category.id ? '#ef6c00' : "black",
        'background-color': _category.id === this.category.id ? '#fff3e0' : "#fff",
      });
    }
  },
  created() {
    if (this.$route.path.includes("/category/")) {
      let categoryId = +this.$route.params.categoryId;
      this.category = this.categoryList.find(item => item.id === categoryId)
    }
    if (this.category == null) {
      this.category = this.categoryList[0];
    }
    this.informationData = InformationData.queryDataByType(this.category.name);
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickSearch() {
      window.location.href = "/search";
    },
    onClickHomepage() {
      window.location.href = "/";
    },
    onSearchByTag(category) {
      window.location.href = "/category/" + category.id;
    },
    onClickItem(id) {
      window.location.href = "/details/" + id;
    },
    getUrlPath(imageUrl) {
      return imageUrl.startsWith("/") ? this.ossUrl + imageUrl : this.ossUrl + "/" + imageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      background-color: #ef6c00;
      //background-color: #639361;
      height: 48px;
      padding: 0 15px;
      justify-content: space-between;
      z-index: 999;

      .logoDiv .logoImage {
        display: block;
        height: 31px;
      }

      .headerSvgDiv {
        width: 26px;
        height: 26px;
      }
    }

    .informationDataDiv {
      display: flex;
      flex-wrap: wrap;

      .div {
        width: 100%;
        text-align: left;
        padding: 5px;
      }

      .informationSection {
        width: 100%;
        margin-bottom: 15px;
      }

      .informationTitle {
        padding-left: 5px;
        font-size: 12px;
        font-weight: bold;
        color: #3D3D3D;
        padding-bottom: 5px;
        border-bottom: 0.5px solid #C2C2C2;
        margin-bottom: 5px;
      }

      .fifthItem {
        padding: 5px;

        :first-child {
          margin-top: 0 !important;
        }

        .informationItem {
          margin-top: 15px;
          display: flex;
        }

        .coverImg {
          width: 100px;
          height: 75px;
        }

        .informationContent {
          width: calc(100% - 110px);
          padding-right: 10px;
          display: flex;
          align-content: space-around;
          flex-wrap: wrap;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 20px;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .fourthItem {
        padding: 5px;

        :first-child {
          margin-top: 0 !important;
        }

        .informationItem {
          margin-top: 15px;
        }

        .informationContent {
          .title {
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 20px;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .thirdItem {
        padding: 5px;

        :first-child {
          margin-top: 0 !important;
        }

        .informationItem {
          margin-top: 15px;
        }

        .coverImg {
          height: 160px;
        }

        .informationContent {
          .title {
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 20px;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .secondItem {
        padding: 5px;

        :first-child {
          margin-top: 0 !important;
        }

        .informationItem {
          margin-top: 15px;
          display: flex;
        }

        .coverImg {
          width: 100px;
          height: 75px;
        }

        .informationContent {
          width: calc(100% - 110px);
          padding-left: 10px;
          display: flex;
          align-content: space-around;
          flex-wrap: wrap;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 16px;
            color: #3D3D3D;
            font-weight: bold;
            line-height: 20px;
          }

          .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            color: #666666;
            margin-top: 5px;
          }
        }
      }

      .firstItem {
        display: flex;
        flex-wrap: wrap;

        :first-child {
          width: 100% !important;

          .coverImg {
            height: 100% !important;
          }
        }

        .informationItem {
          position: relative;
          width: calc(25% - 10px);
          margin: 5px;

          .coverImg {
            height: 60px;
          }
        }

        .informationSubTitle {
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.34);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: #FFFFFF;
          border-radius: 2px;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin: 0 12px;
          }
        }
      }

      .coverImg {
        width: 100%;
        display: block;
        object-fit: cover;
        border-radius: 2px;
      }
    }
  }
}
</style>
