import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let store = new Vuex.Store({
    state: {

    },
    getters: {},
    mutations: {

    },
    actions: {}
});
export default store;
