<template>
  <div class="homePageDiv">
    <div class="contentDiv">
      <div class="headerDiv">
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="categoryDrawer = !categoryDrawer">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z"
                  fill="#ffffff"></path>
              <path
                  d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z"
                  fill="#ffffff"></path>
            </svg>
          </el-button>
        </div>
        <div class="logoDiv" @click="onClickHomepage">
          <img class="logoImage" src="/images/logo2.png" alt=""/>
        </div>
        <div class="headerSvgDiv">
          <el-button type="text" style="padding: 0; border: 0;" @click="onClickSearch">
            <svg style="display: block;" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                 width="26" height="26">
              <path
                  d="M426.666667 170.666667a256 256 0 1 0 0 512 256 256 0 0 0 0-512z m-341.333334 256a341.333333 341.333333 0 1 1 610.986667 209.322666l229.845333 229.845334a42.666667 42.666667 0 0 1-60.330666 60.330666l-229.845334-229.845333A341.333333 341.333333 0 0 1 85.333333 426.666667z"
                  fill="#fff"></path>
            </svg>
          </el-button>
        </div>
      </div>
      <div class="informationDataDiv">
        <div class="informationTitle">
          {{ informationData.title }}
        </div>
        <div class="informationContent">
          <div v-html="informationData.content" id="htmlContent"></div>
        </div>
      </div>
      <div class="recommendDataDiv">
        <div class="title">
          —————— Hot Topic ——————
        </div>
        <div class="content">
          <div v-for="(information, index) in recommendDataList" :key="'informationItem_' + index" class="informationItem"
               @click="onClickItem(information.index)">
            <img :src="getUrlPath(information.img)" alt="cover" class="coverImg"/>
            <div class="title">
              {{ information.title }}
            </div>
          </div>

        </div>
      </div>
    </div>
    <el-drawer :with-header="false" size="100%" style="max-width: 750px; margin: auto;"
               direction="ttb" :visible.sync="categoryDrawer" :show-close="false" :append-to-body="true">
      <div :style="getDrawerTitleStyle">
        <el-button type="text" style="padding: 0; border: 0;" @click="categoryDrawer = !categoryDrawer">
          <svg class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
            <path
                d="M563.8 512l262.5-312.9c4.4-5.2 0.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9c-4.4 5.2-0.7 13.1 6.1 13.1h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                fill="#ffffff"></path>
          </svg>
        </el-button>
        <img class="logoImage" src="/images/menu_logo1.png" alt="" style="display: block; width: 89px;"/>
        <div style="width: 30px;"></div>
      </div>
      <div v-for="(_category, index) in categoryList" :key="'news_category_' + index"
           @click="onSearchByTag(_category)" :style="getDrawerItemStyle">
        {{ _category.name }}
      </div>
      <div style="height: 20px;"/>
    </el-drawer>
    <footerUI/>
    <BackTopComp/>
  </div>
</template>

<script>
import footerUI from '../footerUI';
import BackTopComp from "@/view/BackTopComp";
import InformationData from "@/view/template1/model/InformationData";
import InformationDetails from "@/view/template1/model/InformationDetails";

export default {
  components: {
    footerUI, BackTopComp
  },
  data() {
    return {
      categoryDrawer: false,
      category: null,
      categoryList: JSON.parse(JSON.stringify(InformationData.categoryList)),
      ossUrl: "https://www.gamesoss.com/information",
      informationData: {},
      recommendDataList: [],
    };
  },
  computed: {
    getDrawerTitleStyle() {
      return ({
        'height': '48px',
        'background-color': '#ef6c00',
        'width': '100%',
        'display': 'flex',
        'justify-content': 'space-between',
        'padding': '0 10px',
        'align-items': 'center',
        'position': 'sticky',
        'top': '0'
      });
    },
    getDrawerItemStyle() {
      return ({
        'padding': '15px',
        'text-align': 'left',
        'font-size': '16px',
        'font-weight': 'bold',
        "color": "black",
        'background-color': "#fff",
      });
    }
  },
  created() {
    let informationId = +this.$route.params.informationId;
    let informationData = InformationDetails.queryByIndex(informationId);
    informationData.content = informationData.content.replaceAll('__IMG__', this.ossUrl);
    this.informationData = informationData;
    this.recommendDataList = InformationData.queryRecommend(informationId, 5);
  },
  mounted() {
    console.log("-homepage-------mounted");
    window.addEventListener("resize", this.onResize);
    // 页面刷新时的处理逻辑
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    // 清理事件监听
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    console.log("清理事件--------destroyed");
  },
  beforeRouteEnter(to, from, next) {
    // 路由返回到当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteEnter");
    next(() => {
      // 通过 `vm` 访问组件实例
    });
  },

  beforeRouteLeave(to, from, next) {
    // 路由离开当前页面时的处理逻辑
    console.log("000000000000000-----------beforeRouteLeave");
    next();
  },
  methods: {
    onResize() {

    },
    handleBeforeUnload() {

    },
    handleUnload() {

    },
    onClickSearch() {
      window.location.href = "/search";
    },
    onClickHomepage() {
      window.location.href = "/";
    },
    onSearchByTag(category) {
      window.location.href = "/category/" + category.id;
    },
    onClickItem(id) {
      window.location.href = "/details/" + id;
    },
    getUrlPath(imageUrl) {
      return imageUrl.startsWith("/") ? this.ossUrl + imageUrl : this.ossUrl + "/" + imageUrl;
    }
  }
}
</script>

<style lang="scss" scoped>
.homePageDiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;

  .contentDiv {
    flex-grow: 1;

    .headerDiv {
      align-items: center;
      display: flex;
      position: sticky;
      top: 0;
      background-color: #ef6c00;
      height: 48px;
      padding: 0 15px;
      justify-content: space-between;
      z-index: 999;

      .logoDiv .logoImage {
        display: block;
        height: 31px;
      }

      .headerSvgDiv {
        width: 26px;
        height: 26px;
      }
    }

    .informationDataDiv {
      padding: 20px;
      text-align: left;

      .informationTitle {
        font-size: 20px;
        font-weight: bold;
        color: #353535;
        line-height: 22px;
      }

      .informationContent {
        font-size: 14px;
        font-weight: 400;
        color: #3B3B3B;
        line-height: 18px;

        ::v-deep h2 {
          line-height: 25px;
        }

        ::v-deep ul {
          padding: 0;
        }
        /* ul */
        ::v-deep li {
          list-style: none;
        }

        /* img */
        ::v-deep img {
          width: 100%;
          max-height: 180px;
          object-fit: cover;
        }

        ::v-deep img, ::v-deep ._margin {
          box-sizing: border-box;
        }

        /* table */
        ::v-deep table {
          border-collapse: collapse;
          width: 100%;
          margin: 0 auto;
          table-layout: fixed;
          text-indent: initial;
          line-height: normal;
          font-weight: normal;
          font-size: medium;
          font-style: normal;
          text-align: start;
          border-spacing: 2px;
          white-space: normal;
          font-variant: normal;
        }

        ::v-deep table th, ::v-deep table td {
          border: 1px solid #dfe2e5;
          padding: 5px;
          word-wrap: break-word;
        }

        ::v-deep table tbody tr:nth-child(odd) {
          background-color: #ffffff;
        }

        ::v-deep table tbody tr:nth-child(even) {
          background-color: #f2f2f2;
        }
      }
    }

    .recommendDataDiv {
      padding: 0 20px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        text-align: center;
      }

      .content {
        margin-top: 10px;

        .informationItem {
          margin-bottom: 20px;

          .coverImg {
            max-height: 180px;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
            padding: 0;
            box-sizing: content-box;
          }

          .title {
            margin-top: 4px;
            font-size: 15px;
            font-weight: 600;
            color: #000;
            line-height: 20px;
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
