export default {
    TechnologicalList: [
        {
            index: 1,
            type: "Technological Methods",
            title: "From Couch Potato to Fit_ How Wearable Trackers Motivate You",
            img: "uploadsImg_v22/From Couch Potato to Fit_ How Wearable Trackers Motivate You.webp",
            desc: "Tired of feeling like a couch potato?  Dreaming of a healthier, more active you, but struggling to get started? You're definitely not alone.",
        },
        {
            index: 2,
            type: "Technological Methods",
            title: "Can Your Smartphone Make You Healthier? Exploring Mobile Health Apps",
            img: "uploadsImg_v22/Can Your Smartphone Make You Healthier_ Exploring Mobile Health Apps.webp",
            desc: "Remember when your phone's most exciting feature was Snake? Now, that same device holds the potential to revolutionize your health and well-being.",
        },
        {
            index: 110,
            type: "Technological Methods",
            title: "Level Up Your Fitness Game! Unlocking VR Workouts",
            img: "uploadsImg_v22/Level Up Your Fitness Game! Unlocking VR Workouts.webp",
            desc: "Remember those days of forcing yourself onto the treadmill, dreading another monotonous gym session?",
        },
        {
            index: 111,
            type: "Technological Methods",
            title: "The Doctor Will See You Now—On Your Screen! Exploring Telehealth's Rise",
            img: "uploadsImg_v22/The Doctor Will See You Now—On Your Screen! Exploring Telehealth's Rise.webp",
            desc: "The way we access healthcare is undergoing a dramatic transformation.",
        },
        {
            index: 112,
            type: "Technological Methods",
            title: "Don't Just Weigh Yourself—Understand Yourself with Smart Scales",
            img: "uploadsImg_v22/Don't Just Weigh Yourself—Understand Yourself with Smart Scales.webp",
            desc: "Have you ever stared at the number on your bathroom scale, feeling confused and frustrated despite your best efforts to eat healthy and exercise?",
        },
        {
            index: 113,
            type: "Technological Methods",
            title: "Are You Measuring Fitness Wrong? The Truth About Body Composition Analyzers",
            img: "uploadsImg_v22/Are You Measuring Fitness Wrong_ The Truth About Body Composition Analyzers.webp",
            desc: "I used to be a slave to the bathroom scale. Every morning, my mood hinged on that little number staring back at me.",
        },
        {
            index: 114,
            type: "Technological Methods",
            title: "Unlock Your Potential: Artificial Intelligence as Your Personal Coach",
            img: "uploadsImg_v22/Unlock Your Potential_ Artificial Intelligence as Your Personal Coach.webp",
            desc: "The idea of a coach who knows you better than you know yourself, available at any moment, tirelessly analyzing your progress—it's a compelling vision.",
        },
        {
            index: 115,
            type: "Technological Methods",
            title: "From DNA to Dinner Plate: How Genetics Shape Your Eating Habits",
            img: "uploadsImg_v22/From DNA to Dinner Plate_ How Genetics Shape Your Eating Habits.webp",
            desc: "My friend swears she could live on dark chocolate and broccoli, while I can barely stomach either.",
        },
        {
            index: 116,
            type: "Technological Methods",
            title: "10 Smart Kitchen Devices That Will Transform Your Culinary Experience",
            img: "uploadsImg_v22/10 Smart Kitchen Devices That Will Transform Your Culinary Experience.webp",
            desc: "Remember slaving over a hot stove, juggling timers, and desperately hoping your soufflé wouldn't collapse? The kitchen used to be a battleground.",
        },
        {
            index: 117,
            type: "Technological Methods",
            title: "Discover the Power of Online Fitness Communities: Transform Your Health Now!",
            img: "uploadsImg_v22/Discover the Power of Online Fitness Communities_ Transform Your Health Now!.webp",
            desc: "Remember those New Year's resolutions about getting fit? Yeah, me too.",
        },
    ],
    InteriorList: [
        {
            index: 3,
            type: "Interior Design Trends",
            title: "Scandinavian Style Secrets You Need to Know Now!",
            img: "uploadsImg_v22/Scandinavian Style Secrets You Need to Know Now!.webp",
            desc: "Imagine curling up on a sheepskin rug with a cup of steaming tea, the soft glow of candlelight dancing on the walls as rain patters gently against the window.",
        },
        {
            index: 4,
            type: "Interior Design Trends",
            title: "Embrace the Unexpected! The Magic of Eclectic Decor",
            img: "uploadsImg_v22/Embrace the Unexpected! The Magic of Eclectic Decor.webp",
            desc: "My grandmother's house was a riot of color and texture.",
        },
        {
            index: 5,
            type: "Interior Design Trends",
            title: "Unlocking Space_ The Open Concept Revolution in Modern Homes",
            img: "uploadsImg_v22/Unlocking Space_ The Open Concept Revolution in Modern Homes.webp",
            desc: "Remember the cramped, compartmentalized homes of our grandparents? Walls dictated activity, conversations were confined, and the kitchen was a solitary domain.",
        },
        {
            index: 6,
            type: "Interior Design Trends",
            title: "Why Textured Fabrics Are the Must-Have Trend This Season",
            img: "uploadsImg_v22/Why Textured Fabrics Are the Must-Have Trend This Season.webp",
            desc: "Run your fingers across a smooth, cool slip of silk, then over the nubby warmth of a wool tweed.",
        },
        {
            index: 7,
            type: "Interior Design Trends",
            title: "Why Natural Materials Are the Future of Sustainable Living!",
            img: "uploadsImg_v22/Why Natural Materials Are the Future of Sustainable Living!.webp",
            desc: "Have you ever walked through a forest, breathing in the scent of pine needles and feeling the cool earth beneath your feet? That sense of connection, of peace, is something we’re intrinsically drawn to.",
        },
        {
            index: 8,
            type: "Interior Design Trends",
            title: "10 Must-Have Smart Home Devices to Upgrade Your Life",
            img: "uploadsImg_v22/10 Must-Have Smart Home Devices to Upgrade Your Life.webp",
            desc: "Remember the Jetsons? That futuristic dream of a connected home is closer than you think.",
        },
        {
            index: 9,
            type: "Interior Design Trends",
            title: "From Gatsby to Now_ The Resurgence of Art Deco Style",
            img: "uploadsImg_v22/From Gatsby to Now_ The Resurgence of Art Deco Style.webp",
            desc: "My grandmother's powder compact, a sleek black enamel case inlaid with mother-of-pearl, first sparked my fascination with Art Deco.",
        },
    ],
    SustainableList: [
        {
            index: 10,
            type: "Sustainable Living",
            title: "From Trash to Treasure_ Transforming Your Life with Zero Waste",
            img: "uploadsImg_v22/From Trash to Treasure_ Transforming Your Life with Zero Waste.webp",
            desc: "I used to be horrified by the overflowing trash cans in my own kitchen.",
        },
        {
            index: 11,
            type: "Sustainable Living",
            title: "10 Eco-Friendly Renovation Ideas to Make Your Home Greener",
            img: "uploadsImg_v22/10 Eco-Friendly Renovation Ideas to Make Your Home Greener.webp",
            desc: "The gentle patter of rain on a newly installed, sustainably sourced roof, the sun's warmth streaming through energy-efficient windows, the quiet hum of a high-efficiency appliance – these are the sensory experiences of a truly sustainable home.",
        },
        {
            index: 12,
            type: "Sustainable Living",
            title: "How to Slash Your Energy Bills_ The Ultimate Guide to Efficiency",
            img: "uploadsImg_v22/How to Slash Your Energy Bills_ The Ultimate Guide to Efficiency.webp",
            desc: "Remember when your biggest energy worry was leaving the porch light on all night? Now, with soaring energy prices and growing climate concerns, managing our home energy use feels like a high-stakes game.",
        },
        {
            index: 13,
            type: "Sustainable Living",
            title: "Is Your Closet Killing the Planet? Unveiling the Truth About Sustainable Fashion",
            img: "uploadsImg_v22/Is Your Closet Killing the Planet_ Unveiling the Truth About Sustainable Fashion.webp",
            desc: "Remember that thrill of finding the perfect dress for a special occasion?  The way it flowed, the perfect shade, the feeling of confidence it gave you?  Now imagine that dress, along with countless others, piling up in a landfill, leaching toxins into the earth.",
        },
        {
            index: 14,
            type: "Sustainable Living",
            title: "Mindless Spending Exposed_ Embrace Mindful Consumption Now!",
            img: "uploadsImg_v22/Mindless Spending Exposed_ Embrace Mindful Consumption Now!.webp",
            desc: "Last month, I stared at my credit card bill in disbelief.",
        },
        {
            index: 15,
            type: "Sustainable Living",
            title: "10 Surprising Benefits of Switching to a Plant-Based Diet",
            img: "uploadsImg_v22/_10 Surprising Benefits of Switching to a Plant-Based Diet_.webp",
            desc: "Have you ever considered the profound impact your food choices have, not only on your physical well-being but also on your mental clarity, sleep quality, and even the radiance of your skin?",
        },
        {
            index: 16,
            type: "Sustainable Living",
            title: "Could Your Vacation Save the Planet? Exploring Eco-Friendly Travel Options",
            img: "uploadsImg_v22/Could Your Vacation Save the Planet_ Exploring Eco-Friendly Travel Options.webp",
            desc: "I remember standing on the edge of the Perito Moreno Glacier in Patagonia, mesmerized by its colossal beauty, yet deeply troubled by the visible evidence of its retreat.",
        },
        {
            index: 17,
            type: "Sustainable Living",
            title: "10 Game-Changing Sustainable Technologies You Need to Know About",
            img: "uploadsImg_v22/10 Game-Changing Sustainable Technologies You Need to Know About.webp",
            desc: "Our planet faces unprecedented environmental challenges, but amidst the urgency, a wave of innovation offers a beacon of hope.",
        },
        {
            index: 18,
            type: "Sustainable Living",
            title: "Biodiversity at Risk! Why Wildlife Conservation Matters Now More Than Ever",
            img: "uploadsImg_v22/Biodiversity at Risk! Why Wildlife Conservation Matters Now More Than Ever.webp",
            desc: "The orangutan’s eyes, deep and knowing, held mine for a long moment.",
        },
    ],
    FitnessList: [
        {
            index: 19,
            type: "Fitness Equipment",
            title: "What's Holding You Back? Unleash Your True Potential",
            img: "uploadsImg_v22/What's Holding You Back_ Unleash Your True Potential.webp",
            desc: "My grandmother, a woman whose life was etched with the quiet strength of resilience, decided at 80 years old that she would learn to swim.",
        },
        {
            index: 20,
            type: "Fitness Equipment",
            title: "From Steel to Silicon_ How Strength Meets Innovation",
            img: "uploadsImg_v22/From Steel to Silicon_ How Strength Meets Innovation.webp",
            desc: "The story of human progress is etched in the materials we master.",
        },
        {
            index: 21,
            type: "Fitness Equipment",
            title: "From Cluttered to Zen_ How Redesigning Your Space Can Transform Your Life",
            img: "uploadsImg_v22/From Cluttered to Zen_ How Redesigning Your Space Can Transform Your Life.webp",
            desc: "I used to think my messy desk was a sign of creative genius.",
        },
        {
            index: 22,
            type: "Fitness Equipment",
            title: "Unlock Limitless Potential with One Toolkit",
            img: "uploadsImg_v22/Unlock Limitless Potential with One Toolkit.webp",
            desc: "Do you ever feel like you're drowning in a sea of apps and to-do lists, struggling to keep up with the demands of modern life?  Juggling multiple projects, pursuing personal passions, and striving for self-improvement can feel overwhelming.",
        },
        {
            index: 23,
            type: "Fitness Equipment",
            title: "Sweat Today, Shine Tomorrow – Feel the Burn, See the Results",
            img: "uploadsImg_v22/Sweat Today, Shine Tomorrow – Feel the Burn, See the Results.webp",
            desc: "My hands ached, blisters forming under the calluses.",
        },
        {
            index: 24,
            type: "Fitness Equipment",
            title: "Transform Any Space into Your Personal Fitness Studio!",
            img: "uploadsImg_v22/Transform Any Space into Your Personal Fitness Studio!.webp",
            desc: "Dragging myself to the gym after a long day at work felt like another chore.",
        },
        {
            index: 25,
            type: "Fitness Equipment",
            title: "Swipe, Tap, Sweat_ Embrace Fitness at Your Fingertips Today",
            img: "uploadsImg_v22/Swipe, Tap, Sweat_ Embrace Fitness at Your Fingertips Today.webp",
            desc: "Let's be honest, our phones are practically glued to our hands these days.",
        },
        {
            index: 26,
            type: "Fitness Equipment",
            title: "No Membership Required! Your Guide to a Home Gym Lifestyle",
            img: "uploadsImg_v22/No Membership Required! Your Guide to a Home Gym Lifestyle.webp",
            desc: "Remember that time you waited 20 minutes for the squat rack only to have someone hog it for selfies? Yeah, me too.",
        },
        {
            index: 27,
            type: "Fitness Equipment",
            title: "Unlock the Secret to Maximizing Your Workout Efficiency",
            img: "uploadsImg_v22/Unlock the Secret to Maximizing Your Workout Efficiency.webp",
            desc: "Ever feel like you're spending hours at the gym but not seeing the results you crave?  You're sweating, putting in the time, giving it your all, but something's missing.",
        },
        {
            index: 28,
            type: "Fitness Equipment",
            title: "Unlock Peak Performance Without Sacrificing Comfort",
            img: "uploadsImg_v22/Unlock Peak Performance Without Sacrificing Comfort.webp",
            desc: "Remember Michael Jordan, drained and dehydrated, battling the flu in the 1997 NBA Finals?",
        },
    ],
    OrganizingList: [
        {
            index: 29,
            type: "Organizing Spaces",
            title: "10 Mind-Blowing Creative Storage Solutions You Need to Try!",
            img: "uploadsImg_v22/10 Mind-Blowing Creative Storage Solutions You Need to Try!.webp",
            desc: "Feeling overwhelmed by clutter? Reclaiming your space doesn't have to be a daunting task.",
        },
        {
            index: 30,
            type: "Organizing Spaces",
            title: "From Chaos to Calm—Experience the Magic of the KonMari Method",
            img: "uploadsImg_v22/From Chaos to Calm—Experience the Magic of the KonMari Method.webp",
            desc: "I used to dread opening my closet.",
        },
        {
            index: 31,
            type: "Organizing Spaces",
            title: "12 Genius Hacks to Simplify Your Home Every Season",
            img: "uploadsImg_v22/_12 Genius Hacks to Simplify Your Home Every Season_.webp",
            desc: "Is your home a constant source of stress?  Do you feel like you’re drowning in clutter no matter how much you tidy?  Take a deep breath – simplifying your home doesn't have to be an overwhelming project.",
        },
        {
            index: 32,
            type: "Organizing Spaces",
            title: "From Chaos to Clarity_ Transform Your Digital World Today",
            img: "uploadsImg_v22/From Chaos to Clarity_ Transform Your Digital World Today.webp",
            desc: "Remember that sinking feeling when you searched for a crucial file for 20 minutes, only to find it buried in a folder named 'Miscellaneous Documents'? We've all been there.",
        },
        {
            index: 33,
            type: "Organizing Spaces",
            title: "From Chaos to Calm_ Transforming Messy Kids' Rooms into Organized Havens",
            img: "uploadsImg_v22/From Chaos to Calm_ Transforming Messy Kids' Rooms into Organized Havens.webp",
            desc: "The other day, I tripped over a rogue superhero figurine in my son's room – and it wasn't the first time.",
        },
        {
            index: 34,
            type: "Organizing Spaces",
            title: "10 Genius Kitchen Organization Hacks You Wish You Knew Sooner",
            img: "uploadsImg_v22/10 Genius Kitchen Organization Hacks You Wish You Knew Sooner.webp",
            desc: "Remember that time you spent 20 minutes searching for the garlic press, only to find it buried under a pile of plastic bags? Yeah, we've all been there.",
        },
        {
            index: 35,
            type: "Organizing Spaces",
            title: "10 Must-Have Essentials for the Ultimate Home Office Setup",
            img: "uploadsImg_v22/10 Must-Have Essentials for the Ultimate Home Office Setup.webp",
            desc: "Remember balancing your laptop precariously on a stack of cookbooks at the start of the pandemic? We've come a long way.",
        },
        {
            index: 36,
            type: "Organizing Spaces",
            title: "From Chaos to Control_ Embrace the Magic of Routine",
            img: "uploadsImg_v22/From Chaos to Control_ Embrace the Magic of Routine.webp",
            desc: "Do you ever feel like you're constantly putting out fires, juggling a million things, and yet somehow still falling short?",
        },
        {
            index: 37,
            type: "Organizing Spaces",
            title: "Transform Your Space_ Embrace Sustainable Organizing Practices",
            img: "uploadsImg_v22/Transform Your Space_ Embrace Sustainable Organizing Practices.webp",
            desc: "I used to be drowning in clutter. My desk was a mountain of papers, my closet a black hole of forgotten clothes, and my kitchen drawers a jumbled mess of mismatched utensils.",
        },
        {
            index: 38,
            type: "Organizing Spaces",
            title: "Why Traditional Labeling Systems Are Holding Your Business Back",
            img: "uploadsImg_v22/Why Traditional Labeling Systems Are Holding Your Business Back.webp",
            desc: "When a mislabeled pallet of organic dog food ended up in a pet store aisle next to the regular kibble, the manager of 'Pawsitive Pals' faced a dilemma.",
        },
    ],
    FitList: [
        {
            index: 39,
            type: "Fit and Fabulous",
            title: "Unleash Your Inner Strength_ Embrace the Life You Deserve",
            img: "uploadsImg_v22/Unleash Your Inner Strength_ Embrace the Life You Deserve.webp",
            desc: "Life throws curveballs.  We all face moments that push us to our limits, leaving us questioning our capacity to cope.",
        },
        {
            index: 40,
            type: "Fit and Fabulous",
            title: "Unlock Your Full Potential_ How Balancing Your Body and Mind Can Change Your Life",
            img: "uploadsImg_v22/Unlock Your Full Potential_ How Balancing Your Body and Mind Can Change Your Life.webp",
            desc: "We often hear about the importance of a healthy lifestyle, but how many of us truly grasp the profound impact of balancing both our body and mind?",
        },
        {
            index: 41,
            type: "Fit and Fabulous",
            title: "Dance, Run, Jump! Embracing Life Through Movement",
            img: "uploadsImg_v22/Dance, Run, Jump! Embracing Life Through Movement.webp",
            desc: "We are born to move.  From a baby’s first wiggles to the confident stride of an adult, movement is intrinsic to our being.",
        },
        {
            index: 42,
            type: "Fit and Fabulous",
            title: "Mirror, Mirror on the Wall_ Why Loving Yourself Is the Greatest Gift of All",
            img: "uploadsImg_v22/Mirror, Mirror on the Wall_ Why Loving Yourself Is the Greatest Gift of All.webp",
            desc: "I’ll be honest, the phrase “self-love” used to make me cringe. It felt like something reserved for Instagram influencers with perfectly posed photos and #blessed captions.",
        },
        {
            index: 43,
            type: "Fit and Fabulous",
            title: "Unleash Your Potential! Empower Your Fitness Journey",
            img: "uploadsImg_v22/Unleash Your Potential! Empower Your Fitness Journey.webp",
            desc: "I used to dread the gym. The idea of sweating in public, surrounded by seemingly perfect people, filled me with anxiety.",
        },
    ],
    LifestyleList: [
        {
            index: 44,
            type: "Lifestyle Tips",
            title: "Escape the Hustle_ Why Mindful Living is Your Secret Weapon",
            img: "uploadsImg_v22/Escape the Hustle_ Why Mindful Living is Your Secret Weapon.webp",
            desc: "I used to wear my packed schedule like a badge of honor. Juggling a demanding career, family commitments, and a relentless pursuit of 'more,' I thrived on the adrenaline of constant motion.",
        },
        {
            index: 45,
            type: "Lifestyle Tips",
            title: "From Chaos to Calm_ The Power of Regular Decluttering",
            img: "uploadsImg_v22/From Chaos to Calm_ The Power of Regular Decluttering.webp",
            desc: "Our lives are often a whirlwind.  We're constantly juggling work, family, and personal pursuits, rushing from one commitment to the next.",
        },
        {
            index: 46,
            type: "Lifestyle Tips",
            title: "Are You Making These Healthy Meal Prep Mistakes?",
            img: "uploadsImg_v22/Are You Making These Healthy Meal Prep Mistakes_.webp",
            desc: "Meet Mark, a single dad juggling a demanding job and two energetic kids",
        },
        {
            index: 47,
            type: "Lifestyle Tips",
            title: "Cozy Up! How to Create the Perfect Warm and Welcoming Home",
            img: "uploadsImg_v22/Cozy Up! How to Create the Perfect Warm and Welcoming Home.webp",
            desc: "The feeling of 'home' is more than just bricks and mortar; it's a sensory experience, a warm embrace that envelops you the moment you step inside.",
        },
        {
            index: 48,
            type: "Lifestyle Tips",
            title: "From Mess to Mastery_ How to Establish an Effective Routine",
            img: "uploadsImg_v22/_From Mess to Mastery_ How to Establish an Effective Routine_.webp",
            desc: "Do you ever feel like you're constantly chasing your tail, flitting from one task to another without making real progress?",
        },
        {
            index: 49,
            type: "Lifestyle Tips",
            title: "From Screen Time to Me Time_ The Power of Digital Detox",
            img: "uploadsImg_v22/From Screen Time to Me Time_ The Power of Digital Detox.webp",
            desc: "Our lives are interwoven with the digital tapestry of the 21st century.",
        },
        {
            index: 50,
            type: "Lifestyle Tips",
            title: "Why Connecting with Your Community Will Change Your Life",
            img: "uploadsImg_v22/Why Connecting with Your Community Will Change Your Life.webp",
            desc: "Have you ever scrolled through social media, surrounded by digital 'friends,' yet felt utterly alone?  It's a common paradox in our hyper-connected world.",
        },
    ],
    BudgetList: [
        {
            index: 51,
            type: "Budget-Friendly Ideas",
            title: "10 Surprising Facts About Online Marketplaces You Need to Know",
            img: "uploadsImg_v22/10 Surprising Facts About Online Marketplaces You Need to Know.webp",
            desc: "Remember the last time you needed a quirky birthday gift? A vintage board game? A handmade scarf? Chances are, you turned to an online marketplace.",
        },
        {
            index: 52,
            type: "Budget-Friendly Ideas",
            title: "From Trash to Treasure_ 10 Creative Ways to Repurpose Old Furniture",
            img: "uploadsImg_v22/From Trash to Treasure_ 10 Creative Ways to Repurpose Old Furniture.webp",
            desc: "My grandmother's old vanity sat in our attic for years, gathering dust and cobwebs.",
        },
        {
            index: 53,
            type: "Budget-Friendly Ideas",
            title: "The DIY Secrets Handymen Don't Want You to Know",
            img: "uploadsImg_v22/The DIY Secrets Handymen Don't Want You to Know.webp",
            desc: "Ever feel that familiar pang of dread when you hear the drip...drip...drip of a leaky faucet?",
        },
        {
            index: 54,
            type: "Budget-Friendly Ideas",
            title: "Confessions of a Thriftaholic_ My Best Finds Yet",
            img: "uploadsImg_v22/Confessions of a Thriftaholic_ My Best Finds Yet.webp",
            desc: "My heart pounds, my palms are slick with sweat, and a thrill, like the rush of discovering buried treasure, surges through me.",
        },
        {
            index: 55,
            type: "Budget-Friendly Ideas",
            title: "DIY Fashion_ How to Create Stunning Looks with Budget-Friendly Fabrics",
            img: "uploadsImg_v22/DIY Fashion_ How to Create Stunning Looks with Budget-Friendly Fabrics.webp",
            desc: "I used to think a stylish wardrobe was synonymous with a hefty credit card bill.",
        },
    ],
    DietFoodList: [
        //  Diet Food
        {
            index: 56,
            type: "Diet Food",
            title: "Treat Yourself! The Art of Guilt-Free Indulgence",
            img: "uploadsImg_v22/Treat Yourself! The Art of Guilt-Free Indulgence.webp",
            desc: "We live in a culture obsessed with restriction.",
        },
        {
            index: 57,
            type: "Diet Food",
            title: "Unlock the Secret to Harmony_ Balanced Meals for a Balanced Life!",
            img: "uploadsImg_v22/Unlock the Secret to Harmony_ Balanced Meals for a Balanced Life!.webp",
            desc: "Remember that 3 PM slump? That foggy feeling that makes even the simplest tasks feel monumental?",
        },
        {
            index: 58,
            type: "Diet Food",
            title: "From Spark to Flame_ Fuel Your Goals Today",
            img: "uploadsImg_v22/From Spark to Flame_ Fuel Your Goals Today.webp",
            desc: "We’ve all felt it—that sudden flash of insight, the exhilarating rush of a new idea, the pure, unadulterated thrill of inspiration.",
        },
        {
            index: 59,
            type: "Diet Food",
            title: "5 Simple Steps to Eat Smart and Live a Vibrant Life",
            img: "uploadsImg_v22/5 Simple Steps to Eat Smart and Live a Vibrant Life.webp",
            desc: "Do you dream of waking up each morning feeling energized, focused, and ready to tackle the day?",
        },
        {
            index: 60,
            type: "Diet Food",
            title: "Satisfy Your Taste Buds_ Low-Calorie, High-Flavor Dishes",
            img: "uploadsImg_v22/Satisfy Your Taste Buds_ Low-Calorie, High-Flavor Dishes.webp",
            desc: "I used to think 'diet food' meant flavorless, sad salads and dry chicken.",
        },
        {
            index: 61,
            type: "Diet Food",
            title: "Break Up with Diets_ Say Hello to a Healthier, Happier You",
            img: "uploadsImg_v22/Break Up with Diets_ Say Hello to a Healthier, Happier You.webp",
            desc: "Are you tired of the endless cycle of restrictive diets, the constant pressure to achieve the 'perfect' body, and the nagging feeling of failure when the latest fad diet inevitably falls short?",
        },
    ],
    MedicalList: [
        // Medical technology
        {
            index: 62,
            type: "Medical technology",
            title: "Unlocking Precision Medicine with AI_ A New Era in Diagnosis and Treatment",
            img: "uploadsImg_v22/Unlocking Precision Medicine with AI_ A New Era in Diagnosis and Treatment.webp",
            desc: "Precision medicine aims to tailor medical treatments to individual characteristics.",
        },
        {
            index: 63,
            type: "Medical technology",
            title: "The Future Is Now_ Wearable Tech Monitoring Your Health Instantly",
            img: "uploadsImg_v22/The Future Is Now_ Wearable Tech Monitoring Your Health Instantly.webp",
            desc: "Maria, a 50-year-old with a family history of heart disease, used to live with the constant, nagging fear of a sudden cardiac event.",
        },
        {
            index: 64,
            type: "Medical technology",
            title: "Is Your Next Surgeon a Robot? Exploring the Future of Robotic Surgery",
            img: "uploadsImg_v22/Is Your Next Surgeon a Robot_ Exploring the Future of Robotic Surgery.webp",
            desc: "The sterile gleam of polished titanium, the almost imperceptible whir of micromotors, the focused concentration of a skilled surgeon seated at a console – these are the hallmarks of the modern operating room, where robotic surgery is rapidly transforming the landscape of healthcare.",
        },
        {
            index: 65,
            type: "Medical technology",
            title: "From Sci-Fi to Reality_ Medical Devices Pushing the Boundaries of Possibility",
            img: "uploadsImg_v22/From Sci-Fi to Reality_ Medical Devices Pushing the Boundaries of Possibility.webp",
            desc: "For decades, science fiction has captivated us with visions of futuristic medical marvels – microscopic robots navigating our bloodstream, artificial organs seamlessly replacing failing ones, and the human mind interfacing directly with computers.",
        },
        {
            index: 66,
            type: "Medical technology",
            title: "Revolutionizing Healthcare_ Big Data's Impact on Modern Medical Strategies",
            img: "uploadsImg_v22/Revolutionizing Healthcare_ Big Data's Impact on Modern Medical Strategies.webp",
            desc: "Big data is undeniably reshaping the medical landscape, offering exciting possibilities for improved diagnostics, treatments, and overall healthcare delivery.",
        },
        {
            index: 67,
            type: "Medical technology",
            title: "Is Your Smartphone the Future Doctor? The Rise of Mobile Health Tech",
            img: "uploadsImg_v22/Is Your Smartphone the Future Doctor_ The Rise of Mobile Health Tech.webp",
            desc: "The idea of diagnosing illnesses or talking to a doctor through your phone once seemed like science fiction.",
        },
    ],
    MentalList: [
        // Mental Health Care
        {
            index: 68,
            type: "Mental Health Care",
            title: "Breaking the Stigma_ Medication as a Lifeline for Mental Health",
            img: "uploadsImg_v22/Breaking the Stigma_ Medication as a Lifeline for Mental Health.webp",
            desc: "Mental health is an integral part of overall well-being, yet it often remains shrouded in stigma, particularly regarding treatment.",
        },
        {
            index: 69,
            type: "Mental Health Care",
            title: "Unlocking the Mind_ A Journey Through Modern Mental Health Therapies",
            img: "uploadsImg_v22/Unlocking the Mind_ A Journey Through Modern Mental Health Therapies.webp",
            desc: "The human mind is a complex and fascinating realm, capable of incredible creativity, deep connection, and remarkable resilience.",
        },
        {
            index: 70,
            type: "Mental Health Care",
            title: "Don't Let Confusion Hold You Back—Understand Your Mental Health Choices Today",
            img: "uploadsImg_v22/Don't Let Confusion Hold You Back—Understand Your Mental Health Choices Today.webp",
            desc: "Imagine you're a college student, juggling classes, a part-time job, and trying to maintain a social life.",
        },
        {
            index: 71,
            type: "Mental Health Care",
            title: "Mind Matters_ Mastering the Maze of Mental Health",
            img: "uploadsImg_v22/Mind Matters_ Mastering the Maze of Mental Health.webp",
            desc: "Just as we prioritize physical health, nurturing our mental well-being is paramount for a fulfilling life.",
        },
        {
            index: 72,
            type: "Mental Health Care",
            title: "Unlocking Healing_ How Art and Music Therapy Transform Mental Health Treatment",
            img: "uploadsImg_v22/Unlocking Healing_ How Art and Music Therapy Transform Mental Health Treatment.webp",
            desc: "The human experience is a tapestry woven with a complex array of emotions, from the vibrant hues of joy and love to the more muted tones of sadness and grief.",
        },
        {
            index: 73,
            type: "Mental Health Care",
            title: "Breaking the Silence_ A Deep Dive into Comprehensive Mental Health Care",
            img: "uploadsImg_v22/Breaking the Silence_ A Deep Dive into Comprehensive Mental Health Care.webp",
            desc: "The weight of the world can sometimes feel unbearable.",
        },
        {
            index: 74,
            type: "Mental Health Care",
            title: "5 Essential Psychotherapy Approaches You Need to Know_ From CBT to DBT",
            img: "uploadsImg_v22/5 Essential Psychotherapy Approaches You Need to Know_ From CBT to DBT.webp",
            desc: "Feeling lost in the world of mental health?  Especially when it comes to therapy?  It's completely understandable.",
        },
    ],
    DietList: [
        // Diet and health
        {
            index: 75,
            type: "Diet and health",
            title: "Are Your Meals Healing or Harming You? Find Out Now",
            img: "uploadsImg_v22/Are Your Meals Healing or Harming You_ Find Out Now.webp",
            desc: "Have you ever stopped to consider how deeply connected your food choices are to your overall well-being? I used to think healthy eating was all about restrictive diets and deprivation, but I've learned that it's so much more than that.",
        },
        {
            index: 76,
            type: "Diet and health",
            title: "Eat Well in a Flash_ Quick Recipes for Busy Lives",
            img: "uploadsImg_v22/Eat Well in a Flash_ Quick Recipes for Busy Lives.webp",
            desc: "Remember that time you were late for a meeting because you were trying to scramble together a semblance of lunch? Yeah, me too.",
        },
        {
            index: 77,
            type: "Diet and health",
            title: "Eat Right Tonight! Understanding Food Groups to Build a Healthy Plate",
            img: "uploadsImg_v22/Eat Right Tonight! Understanding Food Groups to Build a Healthy Plate.webp",
            desc: "We've all been there. That 3 PM crash where you'd sell your soul for a chocolate bar.",
        },
        {
            index: 78,
            type: "Diet and health",
            title: "Eat Right for Your Type_ Discovering the Best Foods for Your Lifestyle",
            img: "uploadsImg_v22/Eat Right for Your Type_ Discovering the Best Foods for Your Lifestyle.webp",
            desc: "We live in a world bombarded with the latest diet fads, but the truth is, a one-size-fits-all approach to healthy eating simply doesn't exist.",
        },
        {
            index: 79,
            type: "Diet and health",
            title: "10 Shocking Facts About Diet and Health You Need to Know Now!",
            img: "uploadsImg_v22/10 Shocking Facts About Diet and Health You Need to Know Now!.webp",
            desc: "Navigating the world of nutrition can feel overwhelming.",
        },
        {
            index: 80,
            type: "Diet and health",
            title: "From Monday Blues to Healthy Yous_ Eat Right Every Day",
            img: "uploadsImg_v22/From Monday Blues to Healthy Yous_ Eat Right Every Day.webp",
            desc: "My Sunday nights used to be consumed by a creeping sense of dread.",
        },
        {
            index: 81,
            type: "Diet and health",
            title: "The Life-Changing Magic of Drinking More Water",
            img: "uploadsImg_v22/The Life-Changing Magic of Drinking More Water.webp",
            desc: "Imagine your body as a high-performance engine.",
        },
        {
            index: 82,
            type: "Diet and health",
            title: "10 Easy Recipes to Kickstart Your Healthy Lifestyle",
            img: "uploadsImg_v22/10 Easy Recipes to Kickstart Your Healthy Lifestyle.webp",
            desc: "I used to think 'healthy lifestyle' meant bland salads and endless hours in the gym.",
        },
        {
            index: 83,
            type: "Diet and health",
            title: "10 Surprising Benefits of Fermented Foods for Your Gut and Beyond",
            img: "uploadsImg_v22/10 Surprising Benefits of Fermented Foods for Your Gut and Beyond.webp",
            desc: "My grandmother, a woman who swore by the power of homemade sauerkraut, always told me, 'A healthy gut is a happy gut.",
        },
        {
            index: 84,
            type: "Diet and health",
            title: "Are You Skipping Breakfast? Here's What You're Missing Out On",
            img: "uploadsImg_v22/Are You Skipping Breakfast_ Here's What You're Missing Out On.webp",
            desc: "In our always-on-the-go world, breakfast often gets the boot.",
        },
    ],
    KeepList: [
        // Keep fit
        {
            index: 85,
            type: "Keep fit",
            title: "Stress Less, Smile More—Everyday Strategies for Mental Health",
            img: "uploadsImg_v22/Stress Less, Smile More—Everyday Strategies for Mental Health.webp",
            desc: "Imagine waking up each morning not with a sense of dread, but with a quiet anticipation for the day ahead.",
        },
        {
            index: 86,
            type: "Keep fit",
            title: "Unlocking Wellness_ Surprising Secrets for a Healthier Life",
            img: "uploadsImg_v22/Unlocking Wellness_ Surprising Secrets for a Healthier Life.webp",
            desc: "We're constantly bombarded with health advice: eat your vegetables, exercise, sleep well.",
        },
        {
            index: 87,
            type: "Keep fit",
            title: "Wellbeing Unveiled_ The One Key You've Been Missing",
            img: "uploadsImg_v22/Wellbeing Unveiled_ The One Key You've Been Missing.webp",
            desc: "We're all chasing it: wellbeing. That feeling of truly flourishing, of being alive and content.",
        },
        {
            index: 88,
            type: "Keep fit",
            title: "Don't Be a Target! How to Strengthen Your Defenses Immediately",
            img: "uploadsImg_v22/Don't Be a Target! How to Strengthen Your Defenses Immediately.webp",
            desc: "We live in a world brimming with opportunities, but let's be real, vulnerabilities are lurking around every corner too.",
        },
        {
            index: 89,
            type: "Keep fit",
            title: "Are You Missing Out? The Vital Link Between Sleep and Health Explained",
            img: "uploadsImg_v22/Are You Missing Out_ The Vital Link Between Sleep and Health Explained.webp",
            desc: "Do you ever wake up feeling groggy, even after a full night's 'sleep'?  Are you constantly battling brain fog and struggling to focus?",
        },
        {
            index: 90,
            type: "Keep fit",
            title: "Move More, Gain More_ Unveiling the Unexpected Advantages of Daily Exercise",
            img: "uploadsImg_v22/Move More, Gain More_ Unveiling the Unexpected Advantages of Daily Exercise.webp",
            desc: "Ever felt that invigorating rush after a brisk walk or a satisfying workout?  It's more than just a feeling.",
        },
        {
            index: 91,
            type: "Keep fit",
            title: "10 Proven Strategies to Safeguard Your Health Before Illness Strikes",
            img: "uploadsImg_v22/10 Proven Strategies to Safeguard Your Health Before Illness Strikes.webp",
            desc: "We often hear the saying, 'An ounce of prevention is worth a pound of cure,' but how many of us truly live by it?",
        },
        {
            index: 92,
            type: "Keep fit",
            title: "Early Detection Saves Lives_ Understanding the Critical Role of Health Screenings",
            img: "uploadsImg_v22/Early Detection Saves Lives_ Understanding the Critical Role of Health Screenings.webp",
            desc: "We often hear the adage 'prevention is better than cure,' and nowhere is this truer than with our health.",
        },
        {
            index: 93,
            type: "Keep fit",
            title: "Boost Your Mood Today! Effective Emotional Health Tips for Daily Life",
            img: "uploadsImg_v22/Boost Your Mood Today! Effective Emotional Health Tips for Daily Life.webp",
            desc: "I remember a time when my emotions felt like a runaway train.",
        },
    ],
    FamilyList: [
        // Family Health
        {
            index: 94,
            type: "Family Health",
            title: "Is Your Home Making You Sick? Discover Essential Strategies for Wellness",
            img: "uploadsImg_v22/Is Your Home Making You Sick_ Discover Essential Strategies for Wellness.webp",
            desc: "Our homes should be our sanctuaries, havens of comfort and respite from the world outside.",
        },
        {
            index: 95,
            type: "Family Health",
            title: "5 Simple Steps to Crafting a Wellness Plan for Everyone",
            img: "uploadsImg_v22/5 Simple Steps to Crafting a Wellness Plan for Everyone.webp",
            desc: "Wellness. It's a word that gets thrown around a lot these days, often conjuring images of green smoothies and yoga poses.",
        },
        {
            index: 96,
            type: "Family Health",
            title: "Healthy Habits, Happy Family_ Unlock the Secrets of Nutrition and Well-being",
            img: "uploadsImg_v22/Healthy Habits, Happy Family_ Unlock the Secrets of Nutrition and Well-being.webp",
            desc: "The laughter of children echoing through the house, the warmth of a shared meal, the simple comfort of togetherness – these are the precious moments that define a happy family.",
        },
        {
            index: 97,
            type: "Family Health",
            title: "Breaking the Silence_ How Families Can Support Each Other's Mental Health",
            img: "uploadsImg_v22/Breaking the Silence_ How Families Can Support Each Other's Mental Health.webp",
            desc: "The aroma of Mom's famous lasagna filled the kitchen, but the usual lively chatter was replaced by strained silence.",
        },
        {
            index: 98,
            type: "Family Health",
            title: "Adventure Awaits_ Exciting Outdoor Activities for Fit Families",
            img: "uploadsImg_v22/Adventure Awaits_ Exciting Outdoor Activities for Fit Families.webp",
            desc: "Our family's annual camping trip to Yosemite always starts with a frantic scramble for gear, a chorus of 'Are we there yets?', and a healthy dose of pre-trip jitters.",
        },
    ],
    HealthyList: [
        // Healthy life
        {
            index: 99,
            type: "Healthy life",
            title: "From Anxiety to Zen_ Master Stress for Better Mental and Physical Health",
            img: "uploadsImg_v22/From Anxiety to Zen_ Master Stress for Better Mental and Physical Health.webp",
            desc: "My commute was a nightmare this morning. Stuck in traffic, late for a meeting, my phone buzzing with urgent emails – I felt the familiar knot of anxiety tightening in my chest.",
        },
        {
            index: 100,
            type: "Healthy life",
            title: "Unlocking the Night_ How Sleep Boosts Your Brain Power",
            img: "uploadsImg_v22/Unlocking the Night_ How Sleep Boosts Your Brain Power.webp",
            desc: "We live in a culture obsessed with productivity, pushing us to burn the midnight oil.",
        },
        {
            index: 101,
            type: "Healthy life",
            title: "10 Surprising Benefits of Preventive Healthcare for Your Overall Wellness",
            img: "uploadsImg_v22/10 Surprising Benefits of Preventive Healthcare for Your Overall Wellness.webp",
            desc: "We all know the drill:  Visit the doctor when we're sick, grab some medicine, and hope to feel better soon.",
        },
        {
            index: 102,
            type: "Healthy life",
            title: "No Time to Cook? Simple Nutrition Hacks for Busy People",
            img: "uploadsImg_v22/No Time to Cook_ Simple Nutrition Hacks for Busy People.webp",
            desc: "The alarm clock screams, emails pile up, deadlines loom – sound familiar?",
        },
        {
            index: 103,
            type: "Healthy life",
            title: "Unlock the Secret to a Lifestyle That Fits You Perfectly",
            img: "uploadsImg_v22/Unlock the Secret to a Lifestyle That Fits You Perfectly.webp",
            desc: "We're constantly bombarded with images of 'perfect' lifestyles: exotic vacations, dream homes, fulfilling careers.",
        },

    ],
    UnderstandingList: [
        {
            index: 104,
            type: "Understanding Chronic Diseases",
            title: "Don't Wait Until It's Too Late_ Mastering Prevention and Management Now",
            img: "uploadsImg_v22/Don't Wait Until It's Too Late_ Mastering Prevention and Management Now.webp",
            desc: "Last year, a close friend of mine faced a major financial setback.",
        },
        {
            index: 105,
            type: "Understanding Chronic Diseases",
            title: "Food as Medicine_ Unlocking the Secret to Managing Chronic Diseases",
            img: "uploadsImg_v22/Food as Medicine_ Unlocking the Secret to Managing Chronic Diseases.webp",
            desc: "Imagine a world where managing chronic diseases like heart disease, diabetes, and even certain cancers relies less on pharmaceuticals and more on the food we consume.",
        },
        {
            index: 106,
            type: "Understanding Chronic Diseases",
            title: "10 Holistic Practices That Can Revolutionize Your Chronic Disease Management",
            img: "uploadsImg_v22/10 Holistic Practices That Can Revolutionize Your Chronic Disease Management.webp",
            desc: "Living with a chronic disease can feel like a constant uphill battle.",
        },
        {
            index: 107,
            type: "Understanding Chronic Diseases",
            title: "The Silent Saboteur_ How Stress Triggers Chronic Illness",
            img: "uploadsImg_v22/The Silent Saboteur_ How Stress Triggers Chronic Illness.webp",
            desc: "My hands trembled as I gripped the steering wheel, the traffic jam stretching endlessly before me.",
        },
        {
            index: 108,
            type: "Understanding Chronic Diseases",
            title: "The Ultimate Chronic Pain Survival Guide_ Strategies You Need to Know",
            img: "uploadsImg_v22/The Ultimate Chronic Pain Survival Guide_ Strategies You Need to Know.webp",
            desc: "Imagine waking up every morning with a dull ache that shadows you throughout the day.",
        },
        {
            index: 109,
            type: "Understanding Chronic Diseases",
            title: "Are You Skipping Check-Ups? How It Impacts Your Chronic Disease Management",
            img: "uploadsImg_v22/Are You Skipping Check-Ups_ How It Impacts Your Chronic Disease Management.webp",
            desc: "Life is a whirlwind. We're constantly juggling work, family, and countless other responsibilities, often pushing our own well-being to the back burner.",
        },
    ],
// 新增
    softwareList: [
        {
            index: 118,
            type: "Software",
            title: "IaaS vs. PaaS vs. SaaS: Which Cloud Model Suits Your Business?",
            img: "uploadsImg_v22/IaaS vs. PaaS vs. SaaS_ Which Cloud Model Suits Your Business_.webp",
            desc: "The cloud isn't just a buzzword anymore; it's the engine room of modern business.",
        },
        {
            index: 119,
            type: "Software",
            title: "10 Incredible Ways Linux and Apache Are Shaping the Future of the Internet",
            img: "uploadsImg_v22/10 Incredible Ways Linux and Apache Are Shaping the Future of the Internet.webp",
            desc: "The internet, our modern world's dynamic nervous system, constantly evolves.",
        },
        {
            index: 120,
            type: "Software",
            title: "From Frustration to Delight: The Power of Exceptional User Experience",
            img: "uploadsImg_v22/From Frustration to Delight_ The Power of Exceptional User Experience.webp",
            desc: "Imagine trying to book a flight online. The dropdown menu for selecting the number of passengers stubbornly defaults to '1,' even though you've already entered '3' in a previous field.",
        },
        {
            index: 121,
            type: "Software",
            title: "Agile Development: Revolutionizing How We Build Software",
            img: "uploadsImg_v22/Agile Development_ Revolutionizing How We Build Software.webp",
            desc: "Imagine designing a complex e-commerce platform. You spend months meticulously researching the market, crafting the perfect architecture, and coding the platform to perfection.",
        },
        {
            index: 122,
            type: "Software",
            title: "DevOps Secrets Revealed: Transforming Your Workflow Today",
            img: "uploadsImg_v22/DevOps Secrets Revealed_ Transforming Your Workflow Today.webp",
            desc: "The old way of building software often felt like a relay race gone wrong.",
        },
    ],
    queryDataByType(type) {
        let list = {}
        if (type === 'Home') {
            list.Budget = this.BudgetList
            list.Fit = this.FitList
            list.Technological = this.TechnologicalList
            list.Sustainable = this.SustainableList
            list.Interior = this.InteriorList

            list.Fitness = this.FitnessList
            list.Organizing = this.OrganizingList
            list.Lifestyle = this.LifestyleList
            list.DietFood = this.DietFoodList
            list.Medical = this.MedicalList

            list.Mental = this.MentalList
            list.Diet = this.DietList
            list.Keep = this.KeepList
            list.Family = this.FamilyList
            list.Healthy = this.HealthyList
            list.Understanding = this.UnderstandingList
            list.software = this.softwareList
        } else if (type === 'Technological Methods') {
            list.Technological = this.TechnologicalList
        } else if (type === 'Interior Design Trends') {
            list.Interior = this.InteriorList
        } else if (type === 'Sustainable Living') {
            list.Sustainable = this.SustainableList
        } else if (type === 'Fitness Equipment') {
            list.Fitness = this.FitnessList
        } else if (type === 'Organizing Spaces') {
            list.Organizing = this.OrganizingList
        } else if (type === 'Fit and Fabulous') {
            list.Fit = this.FitList
        } else if (type === 'Lifestyle Tips') {
            list.Lifestyle = this.LifestyleList
        } else if (type === 'Budget-Friendly Ideas') {
            list.Budget = this.BudgetList
        } else if (type === 'Diet Food') {
            list.DietFood = this.DietFoodList
        } else if (type === 'Medical technology') {
            list.Medical = this.MedicalList
        } else if (type === 'Mental Health Care') {
            list.Mental = this.MentalList
        } else if (type === 'Diet and health') {
            list.Diet = this.DietList
        } else if (type === 'Keep fit') {
            list.Keep = this.KeepList
        } else if (type === 'Family Health') {
            list.Family = this.FamilyList
        } else if (type === 'Healthy life') {
            list.Healthy = this.HealthyList
        } else if (type === 'Understanding Chronic Diseases') {
            list.Understanding = this.UnderstandingList
        } else if (type === 'Software') {
            list.software = this.softwareList
        }
        let keys = Object.keys(list);
        keys.forEach(function(key) {
            let value = list[key];
            value.map(item => {
                item.title = item.title.replace(/_/g, ':')
            })
        });
        return list
    },

    queryData(value) {
        const list = []
        if (value && value !== '') {
            // 将value存入本地
            localStorage.setItem('recent_search', value)
            let data = this.TechnologicalList.concat(this.InteriorList, this.SustainableList, this.FitnessList, this.OrganizingList,
                this.FitList, this.LifestyleList, this.BudgetList, this.DietFoodList, this.MedicalList, this.MentalList, this.DietList,
                this.KeepList, this.FamilyList, this.HealthyList, this.UnderstandingList, this.softwareList);
            for (let i = 0; i < data.length; i++) {
                if (data[i].type.indexOf(value) !== -1 || data[i].title.indexOf(value) !== -1) {
                    list.push(data[i])
                }
            }
            // 避免list元素重复
            return list.filter((item, index) => list.findIndex(v => v.index === item.index) === index)
        } else {
            return list
        }
    },

    queryRecommend(idx, count) {
        let result = [];
        let data = this.TechnologicalList.concat(this.InteriorList, this.SustainableList, this.FitnessList, this.OrganizingList,
            this.FitList, this.LifestyleList, this.BudgetList, this.DietFoodList, this.MedicalList, this.MentalList, this.DietList,
            this.KeepList, this.FamilyList, this.HealthyList, this.UnderstandingList, this.softwareList);

        while (result.length < count) {
            const index = Math.floor(Math.random() * data.length);
            let temp = data[index];
            if (temp.index !== idx) {
                result.push(data[index]);
            }
            data.splice(index, 1);
        }

        return result;
    },

    categoryList: [
        {id: 0, name: 'Home'},
        {id: 1, name: 'Fit and Fabulous'},
        {id: 2, name: 'Technological Methods'},
        {id: 3, name: 'Interior Design Trends'},
        {id: 4, name: 'Sustainable Living'},
        {id: 5, name: 'Fitness Equipment'},
        {id: 6, name: 'Organizing Spaces'},
        {id: 7, name: 'Lifestyle Tips'},
        {id: 8, name: 'Budget-Friendly Ideas'},
        {id: 9, name: 'Diet Food'},
        {id: 10, name: 'Medical technology'},
        {id: 11, name: 'Mental Health Care'},
        {id: 12, name: 'Diet and health'},
        {id: 13, name: 'Keep fit'},
        {id: 14, name: 'Family Health'},
        {id: 15, name: 'Healthy life'},
        {id: 16, name: 'Understanding Chronic Diseases'},
        {id: 17, name: 'Software'}
    ]
}
