<template>
  <div id="app">
    <router-view :key="$route.fullPath" style="max-width: 750px; margin: auto;"/>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {},
  watch: {},
  data() {
    return {};
  },
  created() {

  },
  mounted() {

  },
  methods: {
    onClickButtonBtn(type) {
      let url = type === "agree" ? "userAgreement" : "privacyPolicy";
      this.$router.push("/" + url);
    }
  }
};
</script>

<style>
#app {
  font-family: roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  background-color: #F2F6FC;
}
</style>
<style lang="scss" scoped>

</style>
